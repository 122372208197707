import Api from './api'
import { PATH } from './endPoints'

const getRegisteredWorkers = async (params) => {
  return Api.get(PATH.ADMIN_WORKERS, { params }).then((res) => res.data)
}

const getRegisteredOrderers = async (params) => {
  return Api.get(PATH.ADMIN_ORDERERS, { params }).then((res) => res.data)
}

const getAvailableWorkers = async ({
  recruitment_id: recruitmentId,
  ...params
}) => {
  return Api.get(`${PATH.ADMIN_AVAILABLE_WORKERS}/${recruitmentId}`, {
    params
  }).then((res) => res.data)
}

const updatePDFFile = async (payload) => {
  const { jobId, formData } = payload
  return Api.post(`${PATH.ADMIN_UPDATE_PDF}/${jobId}`, formData).then(
    (res) => res.data
  )
}

const cancelSelectWorker = async (id) => {
  return Api.delete(`${PATH.CANCEL_SELECT_WORKER}/${id}`).then(
    (res) => res.data
  )
}

const downloadCSVWorkers = async (params) => {
  const validPath = PATH.ADMIN_WORKERS_EXPORT.replace('workers', params.type)
  delete params.type
  return Api.get(validPath, { params }).then((res) => res.data)
}

export default {
  getRegisteredOrderers,
  getRegisteredWorkers,
  getAvailableWorkers,
  updatePDFFile,
  cancelSelectWorker,
  downloadCSVWorkers
}
