export const routePathNames = Object.freeze({
  layout: 'layout',
  dashboard: 'dashboard',
  contact: 'contact',
  profile: 'profile',
  login: 'login',
  register: 'register',
  resetPassword: 'reset_password',
  registerSuccess: 'register_success',
  registeredWorkersProfile: 'registered_workers_profile',
  registeredWorkersProfileRate: 'registered_workers_profile_rate',
  registeredOrderersProfile: 'registered_orderers_profile',
  registeredOrderersProfileRate: 'registered_orderers_profile_rate',
  registeredWorkers: 'registered_workers',
  registeredOrderers: 'registered_orderers',
  recruitments: 'recruitments',
  recruitmentDetails: 'recruitment_details',
  recruitmentSelectWorkers: 'recruitment_select_workers',
  recruitmentSelectedWorker: 'recruitment_selected_worker',
  csvDownload: 'csv_download'
})

export const RecruitmentTypes = Object.freeze({
  APPLIED: 'applied',
  CONSTRACTED: 'contracted',
  ACCOMPLISHED: 'accomplished'
})

export const DateUnits = Object.freeze({
  DAY: '日',
  MONTH: '月',
  YEAR: '年',
  HOUR: '時',
  MINUTE: '分'
})

export const getValidData = (d) => {
  let result
  const unitsList = Object.keys(DateUnits).map((i) => DateUnits[i])

  if (!d?.label && unitsList?.includes?.(d?.[d?.length - 1]))
    result = d.slice(0, d.length - 1)
  if (d?.label) result = d.value

  return result
}

export const genders = ['男', '女', 'その他']

export const LeftMenus = [
  {
    to: routePathNames.registeredWorkers,
    activating: 'registered-workers',
    label: '登録パートナー企業一覧'
  },
  {
    to: routePathNames.registeredOrderers,
    activating: 'registered-orderers',
    label: '登録発注者一覧'
  },
  {
    to: routePathNames.recruitments,
    activating: 'recruitments',
    label: '募集一覧'
  },
  {
    to: routePathNames.csvDownload,
    activating: 'csv-download',
    label: 'CSV出力'
  }
]

export const PageModes = Object.freeze({
  EDIT: 'edit',
  READ: 'read'
})

export const ResponseTypes = Object.freeze({
  SUCCESS: 'success',
  ERROR: 'error'
})

export const ProfileStatuses = Object.freeze({
  APPROVED: '承認済み',
  UNAPPROVED: '未承認'
})

export const defaultRatesWorkerData = [
  {
    evaluationTitle: '依頼内容は明確だったか',
    level: 0
  },
  {
    evaluationTitle: '作業説明は十分だったか',
    level: 0
  },
  {
    evaluationTitle: '作業期間は適切だったか',
    level: 0
  }
]

export const defaultRatesOrdererData = [
  {
    evaluationTitle: '納期は守られたか',
    level: 0
  },
  {
    evaluationTitle: '納品物は不足はなかったか',
    level: 0
  },
  {
    evaluationTitle: '納品物の品質に問題なかったか',
    level: 0
  }
]

export const addressTypes = Object.freeze({
  typeAddress: '場所を指定する',
  companyAddress: '誠進産業社屋へ引き取り',
  adminAddress: '管理者より指定'
})
