import mutations from './mutations'
import actions from './actions'
import getters from './getters'

const initState = {
  registeredWorkers: { data: [], pagination: {} },
  registeredOrderers: { data: [], pagination: {} },
  availableWorkers: { data: [], pagination: {} },
  dashboard: null
}

export default {
  namespaced: true,
  state: () => initState,
  mutations,
  actions,
  getters
}
