import httpAdmin from '@/api/admin'
import snakeCase from 'snakecase-keys'

const getRegisteredWorkers = async ({ commit }, payload = {}) => {
  payload = snakeCase(payload)
  return await httpAdmin.getRegisteredWorkers(payload).then((res) => {
    commit('REGISTERED_WORKERS_UPDATED', res.data)
    return res
  })
}

const getRegisteredOrderers = async ({ commit }, payload = {}) => {
  payload = snakeCase(payload)
  return await httpAdmin.getRegisteredOrderers(payload).then((res) => {
    commit('REGISTERED_ORDERERS_UPDATED', res.data)
    return res
  })
}

const getAvailableWorkers = async ({ commit }, payload = {}) => {
  payload = snakeCase(payload)
  return await httpAdmin.getAvailableWorkers(payload).then((res) => {
    commit('AVAILABLE_WORKERS_UPDATED', res.data)
    return res
  })
}

const updatePDFFile = async ({ _ }, payload = {}) => {
  return await httpAdmin.updatePDFFile(payload).then((res) => {
    return res
  })
}

const cancelSelectWorker = async ({ _ }, payload = {}) => {
  return await httpAdmin.cancelSelectWorker(payload).then((res) => {
    return res
  })
}

const downloadCSVWorkers = async ({ _ }, payload = {}) => {
  payload = snakeCase(payload)
  return await httpAdmin.downloadCSVWorkers(payload).then((res) => {
    return res
  })
}

export default {
  getRegisteredWorkers,
  getRegisteredOrderers,
  getAvailableWorkers,
  updatePDFFile,
  cancelSelectWorker,
  downloadCSVWorkers
}
