import Api from './api'
import { PATH } from './endPoints'

const getRecruitments = async (params) => {
  return Api.get(PATH.ADMIN_RECRUITMENTS, { params: params }).then(
    (res) => res.data
  )
}

const updateRecruitment = async ({ jobId, formData: params }) => {
  return Api.post(`${PATH.ADMIN_RECRUITMENT}/${jobId}`, params).then(
    (res) => res.data
  )
}

const deleteRecruitment = async ({ id }) => {
  return Api.delete(`${PATH.ADMIN_RECRUITMENT}/${id}`).then((res) => res.data)
}

const getRecruitmentDetail = async (params) => {
  const { id } = params
  delete params.id
  return Api.get(`${PATH.ADMIN_RECRUITMENT}/${id}`, {
    params: params
  }).then((res) => res.data)
}

const selectWorkerForRecruitment = async (params) => {
  return Api.post(PATH.ADMIN_SELECT_WORKER, params).then((res) => res.data)
}

export default {
  getRecruitments,
  updateRecruitment,
  getRecruitmentDetail,
  selectWorkerForRecruitment,
  deleteRecruitment
}
