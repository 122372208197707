import httpUser from '@/api/user'
import snakeCase from 'snakecase-keys'

const clear = ({ commit }) => {
  commit('CLEAR_TOKEN')
}

const login = ({ commit }, payload) => {
  return httpUser.login(payload).then((res) => {
    commit('LOGIN', res.data)
    return res
  })
}

const logout = ({ commit }) => {
  return httpUser.logout().then((res) => {
    commit('CLEAR_TOKEN')
    return res
  })
}

const sendMailResetPass = (_, payload) => {
  return httpUser.sendMailResetPass(payload).then((res) => {
    return res.data
  })
}

const onResetPassword = (_, payload) => {
  payload = snakeCase(payload)
  return httpUser.onResetPassword(payload).then((res) => {
    return res.data
  })
}

const register = (_, payload) => {
  payload = snakeCase(payload)
  return httpUser.register(payload).then((res) => {
    return res
  })
}

const getInfoDashboard = ({ commit }) => {
  return httpUser.getInfoDashboard().then((res) => {
    commit('UPDATE_DASHBOARD', res.data)
    return res
  })
}

const getProfile = ({ commit }, payload) => {
  return httpUser.getProfile(payload).then((res) => {
    commit('UPDATE_USER', res.data)
    return res
  })
}

const updateProfile = ({ commit }, payload) => {
  return httpUser.updateProfile(payload).then((res) => {
    commit('UPDATE_USER', res.data)
    return res
  })
}

const deleteProfile = ({ commit }, id) => {
  return httpUser.deleteProfile(id).then((res) => {
    commit('CLEAR_TOKEN', res.data)
    return res
  })
}

const deleteProfileOther = (_, id) => {
  return httpUser.deleteProfile(id).then((res) => {
    return res
  })
}

const sendContact = (_, payload) => {
  return httpUser.sendContact(payload).then((res) => {
    return res.data
  })
}

const getProfileRate = ({ _ }, payload) => {
  return httpUser.getProfileRate(payload).then((res) => {
    return res
  })
}

export default {
  clear,
  login,
  sendMailResetPass,
  onResetPassword,
  register,
  logout,
  getInfoDashboard,
  getProfile,
  updateProfile,
  deleteProfile,
  sendContact,
  getProfileRate,
  deleteProfileOther
}
