const registeredWorkersList = (state) =>
  state.registeredWorkers || { data: [], pagination: {} }
const registeredOrderersList = (state) =>
  state.registeredOrderers || { data: [], pagination: {} }
const availableWorkersList = (state) =>
  state.availableWorkers || { data: [], pagination: {} }

export default {
  registeredWorkersList,
  registeredOrderersList,
  availableWorkersList
}
