import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { routePathNames } from '@/common/constants'

Vue.use(VueRouter)

function checkIsLoggedIn(to, _, next) {
  const token = store.getters['user/token']
  if (!token) {
    next()
  } else {
    next({ name: routePathNames.dashboard })
  }
}

const routes = [
  {
    path: '/',
    name: routePathNames.layout,
    component: () => import('@/views/Layout.vue'),
    redirect: {
      name: routePathNames.dashboard
    },
    beforeEnter: (to, _, next) => {
      const token = store.getters['user/token']
      if (token) {
        if (!to.matched.length) {
          next({ name: routePathNames.dashboard })
        } else {
          next()
        }
      } else {
        next({
          name: routePathNames.login,
          query: {
            redirect: to.fullPath
          }
        })
      }
    },
    children: [
      {
        path: '/dashboard',
        name: routePathNames.dashboard,
        component: () => import('@/views/dashboard/Dashboard.vue')
      },
      {
        path: '/registered-workers/profile/:id',
        name: routePathNames.registeredWorkersProfile,
        component: () => import('@/views/profile/Profile.vue')
      },
      {
        path: '/registered-workers/profile/:id/rate',
        name: routePathNames.registeredWorkersProfileRate,
        component: () => import('@/views/profile/ProfileRate/ProfileRate.vue')
      },
      {
        path: '/registered-orderers/profile/:id',
        name: routePathNames.registeredOrderersProfile,
        component: () => import('@/views/profile/Profile.vue')
      },
      {
        path: '/registered-orderers/profile/:id/rate',
        name: routePathNames.registeredOrderersProfileRate,
        component: () => import('@/views/profile/ProfileRate/ProfileRate.vue')
      },
      {
        path: '/registered-workers',
        name: routePathNames.registeredWorkers,
        component: () =>
          import('@/views/RegisteredWorkers/RegisteredWorkers.vue')
      },
      {
        path: '/registered-orderers',
        name: routePathNames.registeredOrderers,
        component: () =>
          import('@/views/RegisteredOrderers/RegisteredOrderers.vue')
      },
      {
        path: '/recruitments',
        name: routePathNames.recruitments,
        component: () => import('@/views/RecruitmentsList/RecruitmentsList.vue')
      },
      {
        path: '/recruitments/:id',
        name: routePathNames.recruitmentDetails,
        component: () =>
          import(
            '@/views/RecruitmentsList/RecruitmentDetail/RecruitmentDetail.vue'
          )
      },
      {
        path: '/recruitments/:id/select-workers',
        name: routePathNames.recruitmentSelectWorkers,
        component: () =>
          import(
            '@/views/RecruitmentsList/RecruitmentDetail/SelectWorkers/SelectWorkers.vue'
          )
      },
      {
        path: '/recruitments/:recruitmentId/selected-worker/:selectedWorkerId',
        name: routePathNames.recruitmentSelectedWorker,
        component: () =>
          import(
            '@/views/RecruitmentsList/RecruitmentDetail/SelectedWorkerDetails/SelectedWorkerDetails.vue'
          )
      },
      {
        path: '/csv-download',
        name: routePathNames.csvDownload,
        component: () => import('@/views/csv-download/CSVDownload.vue')
      }
    ]
  },
  {
    path: '/login',
    name: routePathNames.login,
    beforeEnter: (to, _, next) => {
      const token = store.getters['user/token']
      if (!token) {
        next()
      } else {
        const { query: { redirect } = {} } = to
        if (redirect) {
          next({ path: redirect })
        } else {
          next({ name: routePathNames.dashboard })
        }
      }
    },
    component: () => import('@/views/auth/login/Login.vue')
  },
  {
    path: '/register',
    name: routePathNames.register,
    beforeEnter: checkIsLoggedIn,
    component: () => import('@/views/auth/register/Register.vue')
  },
  {
    path: '/reset-password',
    name: routePathNames.resetPassword,
    beforeEnter: checkIsLoggedIn,
    component: () => import('@/views/auth/reset-password/ResetPassword.vue')
  },
  {
    path: '/register-success',
    name: routePathNames.registerSuccess,
    beforeEnter: checkIsLoggedIn,
    component: () => import('@/views/auth/register/RegisterSuccess.vue')
  },
  {
    path: '*',
    beforeEnter: (to, _, next) => {
      const token = store.getters['user/token']
      if (token) {
        next({
          name: routePathNames.dashboard
        })
      } else {
        next({
          name: routePathNames.login,
          query: {
            redirect: to.fullPath
          }
        })
      }
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkExactActiveClass: 'is-active',
  routes
})

router.onError((error) => {
  if (/loading chunk .* failed./i.test(error.message)) {
    if (!alert('error router' + error.message)) {
      window.location.reload()
    }
  }
})

export default router
