import mutations from './mutations'
import actions from './actions'
import getters from './getters'

const initState = {
  recruitments: { data: [], pagination: {} },
  querySearchRecruitment: {
    name: null,
    startDate: null,
    endDate: null,
    hourlyWage: null,
    page: 1
  },
  recruitmentsApply: [],
  recruitmentsEstablish: [],
  recruitmentsComplete: [],
  recruitmentDetail: {},
  evaluation: []
}

export default {
  namespaced: true,
  state: () => initState,
  mutations,
  actions,
  getters
}
