import Api from './api'
import { PATH } from './endPoints'

const login = async (params) => {
  return Api.post(PATH.ADMIN_LOGIN, params).then((res) => res.data)
}

const sendMailResetPass = async (params) => {
  return Api.post(PATH.FORGOT_PASSWORD, params).then((res) => res.data)
}

const onResetPassword = async (params) => {
  return Api.post(PATH.RESET_PASSWORD, params).then((res) => res.data)
}

const register = async (params) => {
  return Api.post(PATH.WORKER_REGISTER, params).then((res) => res.data)
}

const loginSocial = async ({ type = 'google' }) => {
  if (type === 'facebook') {
    return Api.get(PATH.AUTH_FACEBOOK).then((res) => res.data)
  }
  return Api.get(`/connect/${type}`).then((res) => res.data)
}

const logout = async () => {
  return Api.post(PATH.ADMIN_LOGOUT).then((res) => res.data)
}

const getInfoDashboard = async () => {
  return Api.get(`${PATH.DASHBOARD_ADMIN}`).then((res) => res.data)
}

const getProfile = async ({ id, urlType }) => {
  return Api.get(`/admin/${urlType}/profile/${id}`).then((res) => res.data)
}

const updateProfile = async (params) => {
  const { id, urlType, formData } = params
  return Api.post(`/admin/${urlType}/profile/${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }).then((res) => res.data)
}

const deleteProfile = async ({ id, urlType }) => {
  return Api.delete(`/admin/${urlType}/profile/${id}`).then((res) => res.data)
}

const sendContact = async (params) => {
  return Api.post(PATH.CONTACT, params).then((res) => res.data)
}

const getProfileRate = async ({ id, urlType }) => {
  return Api.get(`/admin/${urlType}/profile/evaluations/${id}`).then(
    (res) => res.data
  )
}

export default {
  login,
  sendMailResetPass,
  onResetPassword,
  register,
  loginSocial,
  logout,
  getInfoDashboard,
  getProfile,
  updateProfile,
  deleteProfile,
  sendContact,
  getProfileRate
}
