const recruitmentList = (state) =>
  state.recruitments || { data: [], pagination: {} }

const recruitmentApplyList = (state) => state.recruitmentsApply || []

const recruitmentsEstablishList = (state) => state.recruitmentsEstablish || []

const recruitmentsCompleteList = (state) => state.recruitmentsComplete || []

const querySearchRecruitment = (state) => state.querySearchRecruitment

const recruitmentDetail = (state) => state.recruitmentDetail

const evaluation = (state) => state.evaluation

export default {
  recruitmentList,
  recruitmentApplyList,
  recruitmentsEstablishList,
  recruitmentsCompleteList,
  querySearchRecruitment,
  recruitmentDetail,
  evaluation
}
