import httpRecruitment from '@/api/recruitment'
import snakeCase from 'snakecase-keys'
import omitBy from 'lodash/omitBy'
import isNil from 'lodash/isNil'

const getRecruitments = ({ commit }, payload) => {
  payload = omitBy(snakeCase(payload), isNil)
  return httpRecruitment.getRecruitments(payload).then((res) => {
    commit('RECRUITMENT_UPDATED', res.data)
    return res
  })
}

const updateRecruitment = (_, payload) => {
  return httpRecruitment.updateRecruitment(payload).then((res) => {
    return res
  })
}

const deleteRecruitment = (_, payload) => {
  return httpRecruitment.deleteRecruitment(payload).then((res) => {
    return res
  })
}

const getRecruitmentDetail = ({ commit }, payload) => {
  payload = snakeCase(payload)
  return httpRecruitment.getRecruitmentDetail(payload).then((res) => {
    commit('UPDATE_RECRUITMENT_DETAIL', res.data)
    return res
  })
}

const selectWorkerForRecruitment = (_, payload) => {
  payload = snakeCase(payload)
  return httpRecruitment.selectWorkerForRecruitment(payload).then((res) => {
    return res
  })
}

export default {
  getRecruitments,
  updateRecruitment,
  getRecruitmentDetail,
  selectWorkerForRecruitment,
  deleteRecruitment
}
