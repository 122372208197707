const REGISTERED_WORKERS_UPDATED = (state, payload) => {
  const { data = [], currentPage = 1, lastPage = 1 } = payload
  state.registeredWorkers = { data, pagination: { currentPage, lastPage } }
}

const REGISTERED_ORDERERS_UPDATED = (state, payload) => {
  const { data = [], currentPage = 1, lastPage = 1 } = payload
  state.registeredOrderers = { data, pagination: { currentPage, lastPage } }
}

const AVAILABLE_WORKERS_UPDATED = (state, payload) => {
  const { data = [], currentPage = 1, lastPage = 1 } = payload
  state.availableWorkers = { data, pagination: { currentPage, lastPage } }
}

export default {
  REGISTERED_ORDERERS_UPDATED,
  REGISTERED_WORKERS_UPDATED,
  AVAILABLE_WORKERS_UPDATED
}
