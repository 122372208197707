export const PATH = {
  ADMIN_WORKERS: '/admin/workers',
  ADMIN_ORDERERS: '/admin/orderers',
  ADMIN_RECRUITMENTS: '/admin/recruitments',
  ADMIN_RECRUITMENT: '/admin/recruitment',
  ADMIN_SELECT_WORKER: '/admin/select-worker',
  ADMIN_LOGIN: '/admin/login',
  FORGOT_PASSWORD: '/password/forgot-password',
  RESET_PASSWORD: '/password/reset',
  WORKER_REGISTER: '/worker/register',
  AUTH_FACEBOOK: '/auth/facebook',
  ADMIN_LOGOUT: '/admin/logout',
  DASHBOARD_ADMIN: '/admin/dashboard',
  WORKER_PROFILE: '/worker/profile',
  CONTACT: '/worker/contact-us',
  ADMIN_AVAILABLE_WORKERS: '/admin/available-workers',
  ADMIN_UPDATE_PDF: '/admin/job-application',
  CANCEL_SELECT_WORKER: '/admin/cancel-select-worker',
  ADMIN_WORKERS_EXPORT: '/admin/workers/export'
}
